import Link from 'next/link'
import styled from 'styled-components'

import BrandTitle from '~/images/brand_title.svg'

const BasicHeader = () => {
  return (
    <SimpleTopNav>
      <Link href='/' passHref>
        <BrandTitle title='Lumosity' width={122} height={31} aria-labelledby='lumosity' />
      </Link>
    </SimpleTopNav>
  )
}

const SimpleTopNav = styled.div`
  padding: 0px 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 88px;
`

export default BasicHeader
