
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react'

import { Heading1, Subheading1 } from '@lumoslabs/lumosity-storybook'
import type { NextPage } from 'next'
import Head from 'next/head'
import router from 'next/router'
import styled from 'styled-components'

import BasicHeader from '~/components/BasicHeader'
import Footer from '~/components/Footer'
import ButtonWithTracking from '~/components/ui/ButtonWithTracking'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const NotFoundPage: NextPage = () => {
  const t = useTranslationForNamespace('404')

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <BasicHeader />
      <Container>
        <Heading1 as='h1'>{t('title')}</Heading1>
        <Subheading1 as='h2'>{t('message')}</Subheading1>
        <ButtonWithTracking onClick={() => router.push('/')} textKey='returnHome' />
      </Container>
      <Footer />
    </>
  )
}
export default NotFoundPage

const Container = styled.div`
  margin: 50px;
`


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  